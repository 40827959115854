.h-90{
  height: 90%;
}

#main-body {
  width: 100vw;
  // height: 100vh;
  // display: flex;
  min-height: 100vh;
  padding-top: 1.5em;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.btn-primary {
  color: #fff;
}

#list-of-winners {
  width: 100vw;
  left: 0;
  overflow: scroll;
  height: 29.3em;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#list-of-winners::-webkit-scrollbar {
  display: none;
}

[data-displayed="false"] {
  display: none !important;
}

.loader {
  --derp: 2em;
  border: calc(var(--derp) * (2/25)) solid #FFF; /* Light grey */
  border-top: calc(var(--derp) * (2/25)) solid #fbbf1f; /* Blue */
  border-radius: 50%;
  width: var(--derp);
  height: var(--derp);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.main-title {
  text-shadow: 0 0 10px #C5E3FF;
  font-size: 4em;
}
.selected-prize-img{
  height: 18em;
  width: auto;
}
.prize-tag{
  display: none !important;
}

.winner-box {
  background: linear-gradient(180deg, #FF725E 0%, #FB0F0C 100%);
  border: 5px solid #FFF;
  border-radius: 0.5em;
  color: white;
}

.scroll-container,
.winner-name {
  height: 2.6em;
  border: 3px solid #FFF;
  border-radius: 0.5em;
  line-height: 2.4em;
}

.scroll-container {
  padding-top: 0.1em; // To make sure winner's name is perfectly centered.
  overflow: hidden;
}

.winner-name {
  font-size: 1.4em;
}

.scroll-text-animation {
  transform: translateY(-960em);
  animation: myscrollanimation 44s ease 0s;

  /* animation properties */
  -moz-transform: translateY(-960em);
  -webkit-transform: translateY(-960em);
  transform: translateY(-960em);

  -moz-animation: myscrollanimation 44s ease 0s;
  -webkit-animation: myscrollanimation 44s ease 0s;
  animation: myscrollanimation 44s ease 0s;
}

@keyframes myscrollanimation {
  0%   {
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100%   {
    -moz-transform: translateY(-960em);
    -webkit-transform: translateY(-960em);
    transform: translateY(-960em);
  }
}

.scroll-text-animation-short {
  transform: translateY(-240em);
  animation: myscrollanimation-short 10s ease 0s;

  /* animation properties */
  -moz-transform: translateY(-240em);
  -webkit-transform: translateY(-240em);
  transform: translateY(-240em);

  -moz-animation: myscrollanimation-short 10s ease 0s;
  -webkit-animation: myscrollanimation-short 10s ease 0s;
  animation: myscrollanimation-short 10s ease 0s;
}

@keyframes myscrollanimation-short {
  0%   {
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100%   {
    -moz-transform: translateY(-240em);
    -webkit-transform: translateY(-240em);
    transform: translateY(-240em);
  }
}

.preshrink {
  height: 13em;
}

.shrink-image {
  height: 8em;
  animation: shrink-animation 5s ease 0s;
}

@keyframes shrink-animation {
  0%   { height: 13em }
  100% { height: 8em }
}

.contestant-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}