@import "assets/css/custom";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@mixin font_quantico($weight, $style, $filename) {
  @font-face {
    font-family: "Quantico";
    font-weight: $weight;
    font-style: $style;
    src: local("Quantico"), url("assets/fonts/#{ $filename }.ttf") format("truetype");
  }
}

@include font_quantico(400, normal, "Quantico-Regular");
@include font_quantico(400, italic, "Quantico-Italic");
@include font_quantico(700, normal, "Quantico-Bold");
@include font_quantico(700, italic, "Quantico-BoldItalic");

@mixin font_nunito($weight, $style, $filename) {
  @font-face {
    font-family: "Nunito";
    font-weight: $weight;
    font-style: $style;
    src: local("Nunito"), url("assets/fonts/#{ $filename }.ttf") format("truetype");
  }
}

@include font_nunito(200, normal, "Nunito-ExtraLight");
@include font_nunito(200, italic, "Nunito-ExtraLightItalic");
@include font_nunito(300, normal, "Nunito-Light");
@include font_nunito(300, italic, "Nunito-LightItalic");
@include font_nunito(400, normal, "Nunito-Regular");
@include font_nunito(400, italic, "Nunito-Italic");
@include font_nunito(500, normal, "Nunito-Medium");
@include font_nunito(500, italic, "Nunito-MediumItalic");
@include font_nunito(600, normal, "Nunito-SemiBold");
@include font_nunito(600, italic, "Nunito-SemiBoldItalic");
@include font_nunito(700, normal, "Nunito-Bold");
@include font_nunito(700, italic, "Nunito-BoldItalic");
@include font_nunito(800, normal, "Nunito-ExtraBold");
@include font_nunito(800, italic, "Nunito-ExtraBoldItalic");
@include font_nunito(900, normal, "Nunito-Black");
@include font_nunito(900, italic, "Nunito-BlackItalic");

@mixin font_manrope($weight, $style, $filename) {
  @font-face {
    font-family: "Manrope";
    font-weight: $weight;
    font-style: $style;
    src: local("Manrope"), url("assets/fonts/#{ $filename }.ttf") format("truetype");
  }
}

@include font_manrope(200, normal, "Manrope-ExtraLight");
@include font_manrope(300, normal, "Manrope-Light");
@include font_manrope(400, normal, "Manrope-Regular");
@include font_manrope(500, normal, "Manrope-Medium");
@include font_manrope(600, normal, "Manrope-SemiBold");
@include font_manrope(700, normal, "Manrope-Bold");
@include font_manrope(800, normal, "Manrope-ExtraBold");

.font-quantico  { font-family: "Quantico" }
.font-nunito    { font-family: "Nunito" }
.font-manrope   { font-family: "Manrope" }