// For all the theme colors in buttons, backgrounds texts, etc.
$theme-colors: (
    "warning":      #FD9B5B,
    "danger":       #FB0F0C,
    "faintred":     #FFF4F4,
    "success":      #51B848,
    "grey":         #545454,
    "primary":      #0067B3,
    "purple":       #CE3BBF,
    "lightgrey":    #B5B5B5,
    "faintgrey":    #F4F4F4,
    "veryfaint":    #FBFBFB,
    "titleblack":   #231D1D,
    "disabledgrey": #D7D7D7,
    "defaultgrey":  #F2F2F2,
    "black":        #000000
);

// Used for setting changes to paddings & margins. Bootstrap always scales via rem, so I changed them to use em.
$spacers: (
    0: 0,
    1: 0.25em,
    2: 0.5em,
    3: 0.75em,
    4: 1em,
    5: 1.25em,
    6: 1.5em,
    7: 1.75em,
    8: 2em,
    9: 2.25em,
    10: 2.5em,
    11: 2.75em,
    12: 3em,
    13: 3.25em,
    14: 3.5em,
    15: 3.75em,
    16: 4em,
    17: 4.25em,
    18: 4.5em,
    19: 4.75em,
    20: 5em
);

/* Import bootstrap to set changes. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing my own custom scss */
@import "fonts";
@import "scrolls";
@import "widths";
@import "general";
@import "lucky-draw";